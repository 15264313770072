import isFinite from "lodash/isFinite";
import unicodeSubstring from "unicode-substring";
import isArray from "lodash/isArray";
import isString from "lodash/isString";

import { PLAYER_AVATAR_BASE_PATH, PLAYER_AVATAR_CANVAS_SIZE, FONT_NUNITO, CDN_BASE_URL } from "@/constants";

import { BLACK, WHITE, YELLOW_BROWN } from "@/colors";
import { PLAYER_AVATAR_BACKGROUND_COLOR } from "@/constants";
import { distanceString } from "./map";

const SVG_NAMESPACE = "http://www.w3.org/2000/svg";
const XHTML_NAMESPACE = "http://www.w3.org/1999/xhtml";

export const AVATAR_MIME_TYPE = "image/png";
export const AVATAR_DATA_URL = `data:${AVATAR_MIME_TYPE};base64,`;

const AVATAR_SVG_SIZE = 1000;

function getContextFont(fontSize) {
	return `normal normal 900 ${fontSize}px/${fontSize}px ${FONT_NUNITO}`;
}

export function createAvatarName(text, context, width, height) {
	text = (text || "").trim();

	if (context && width && height && text.length > 0) {
		const size = Math.min(width, height);
		const fontSize = size * ((20 * 2) / 256);
		const lineWidth = (size / 256) * 6;

		context.save();
		context.font = getContextFont(size);
		context.textAlign = "center";
		context.fillStyle = WHITE;
		context.strokeStyle = BLACK;
		context.lineWidth = lineWidth;

		const radius = size / 2 - fontSize;

		const mesauredCharacters = [];
		const mesauredAngles = [];
		for (let i = text.length - 1; i >= 0; i--) {
			const position =
				(context.measureText(unicodeSubstring(text, 0, i + 1)).width -
					context.measureText(unicodeSubstring(text, 0, i)).width) /
				(radius / fontSize) /
				Math.PI /
				(size / Math.PI);

			mesauredAngles.unshift(position);
			mesauredCharacters.unshift(unicodeSubstring(text, i, i + 1));
		}

		const characters = [];
		const angles = [];
		let mesauredWidth = 0;
		for (let i = 0; i < mesauredCharacters.length; i++) {
			if (mesauredWidth + mesauredAngles[i] > Math.PI * 2) {
				break;
			}
			mesauredWidth += mesauredAngles[i];
			characters.push(mesauredCharacters[i]);
			angles.push(mesauredAngles[i]);
		}

		context.font = getContextFont(fontSize);

		context.translate(width / 2, height / 2);

		let angle = -mesauredWidth / 2;
		for (let i = 0; i < characters.length; i++) {
			angle += angles[i];
			context.save();
			context.rotate(angle - angles[i] / 2);
			context.translate(0, -1 * radius);
			context.strokeText(characters[i], 0, 0);
			context.fillText(characters[i], 0, 0);
			context.restore();
		}

		context.restore();
	}
}

export function createJoinedText(text, context, width, height) {
	text = (text || "").trim();

	if (context && width && height && text.length > 0) {
		const size = Math.min(width, height);
		const fontSize = size * ((20 * 2) / 256);
		const lineWidth = (size / 256) * 6;

		context.save();
		context.font = getContextFont(size);
		context.textAlign = "center";
		context.fillStyle = WHITE;
		context.strokeStyle = BLACK;
		context.lineWidth = lineWidth;

		const radius = size / 2 - fontSize / 2;

		const mesauredCharacters = [];
		const mesauredAngles = [];
		for (let i = text.length - 1; i >= 0; i--) {
			const position =
				(((context.measureText(unicodeSubstring(text, 0, i + 1)).width -
					context.measureText(unicodeSubstring(text, 0, i)).width) /
					(radius / fontSize) /
					Math.PI) *
					1.5) /
				(size / Math.PI);

			mesauredAngles.unshift(position);
			mesauredCharacters.unshift(unicodeSubstring(text, i, i + 1));
		}

		const characters = [];
		const angles = [];
		let mesauredWidth = 0;
		for (let i = 0; i < mesauredCharacters.length; i++) {
			if (mesauredWidth + mesauredAngles[i] > Math.PI * 2) {
				break;
			}
			mesauredWidth += mesauredAngles[i];
			characters.push(mesauredCharacters[i]);
			angles.push(mesauredAngles[i]);
		}

		context.font = getContextFont(fontSize);

		context.translate(width / 2, height / 2);

		let angle = -mesauredWidth / 2;
		for (let i = 0; i < characters.length; i++) {
			angle += angles[i];
			context.save();
			context.rotate(-angle + angles[i] / 2);
			context.translate(0, radius);
			context.strokeText(characters[i], 0, 0);
			context.fillText(characters[i], 0, 0);
			context.restore();
		}

		context.restore();
	}
}

function drawHostBadge(context, width, height) {
	if (context && width && height) {
		const size = Math.min(width, height);
		const svgSize = Math.round(size / 1.225);
		const fontSize = size * ((24 * 2) / 256);
		const lineWidth = (size / 256) * 6;

		context.save();
		context.font = getContextFont(fontSize);
		context.textAlign = "center";

		context.strokeStyle = BLACK;
		context.lineWidth = lineWidth;

		const x = width / 2;
		const y = size - lineWidth / 2 - (size - svgSize) / 2 + lineWidth / 2 + lineWidth / 1.225;
		const maxWidth = width - (2 * lineWidth) / 2;

		const textMeasure = context.measureText("Host");

		const roundRectX = x - textMeasure.width / 2 - 16;
		const roundRectY = y - fontSize + 1;
		const roundRectWidth = textMeasure.width + 32;
		const roundRectHeight = 32;
		const roundRectRadii = 8;

		context.fillStyle = YELLOW_BROWN;
		context.beginPath();

		context.moveTo(roundRectX, roundRectY + roundRectRadii);
		context.quadraticCurveTo(roundRectX, roundRectY, roundRectX + roundRectRadii, roundRectY);
		context.lineTo(roundRectX + roundRectWidth - roundRectRadii, roundRectY);
		context.quadraticCurveTo(
			roundRectX + roundRectWidth,
			roundRectY,
			roundRectX + roundRectWidth,
			roundRectY + roundRectRadii
		);
		context.lineTo(roundRectX + roundRectWidth, roundRectY + roundRectHeight - roundRectRadii);
		context.quadraticCurveTo(
			roundRectX + roundRectWidth,
			roundRectY + roundRectHeight,
			roundRectX + roundRectWidth - roundRectRadii,
			roundRectY + roundRectHeight
		);
		context.lineTo(roundRectX + roundRectRadii, roundRectY + roundRectHeight);
		context.quadraticCurveTo(
			roundRectX,
			roundRectY + roundRectHeight,
			roundRectX,
			roundRectY + roundRectHeight - roundRectRadii
		);
		context.lineTo(roundRectX, roundRectY + roundRectRadii);

		// context.roundRect(roundRectX, roundRectY, roundRectWidth, roundRectHeight, roundRectRadii);

		context.fill();
		context.stroke();

		context.fillStyle = WHITE;
		context.strokeText("Host", x, y, maxWidth);
		context.fillText("Host", x, y, maxWidth);

		context.restore();
	}
}

function drawDistance(distance, context, width, height) {
	distance = distanceString(distance);

	if (context && width && height) {
		const size = Math.min(width, height);
		const svgSize = Math.round(size / 1.225);
		const fontSize = size * ((18 * 2) / 256);
		const lineWidth = (size / 256) * 6;

		context.save();
		context.font = getContextFont(fontSize);
		context.textAlign = "center";
		context.fillStyle = WHITE;
		context.strokeStyle = BLACK;
		context.lineWidth = lineWidth;

		const x = width / 2;
		const y = size - lineWidth / 2 - (size - svgSize) / 2 + lineWidth / 2 + lineWidth / 1.225;
		const maxWidth = width - (2 * lineWidth) / 2;
		context.strokeText(distance, x, y, maxWidth);
		context.fillText(distance, x, y, maxWidth);

		context.restore();
	}
}

function fetchTextAsync(url) {
	return new Promise((resolve, reject) => {
		if (url) {
			fetch(url)
				.then((response) => response.text())
				.then((text) => void resolve(text))
				.catch(() => void reject());
		} else {
			reject();
		}
	});
}

export async function createAvatarImageJSON({
	avatar = null,
	width = 1000,
	height = 1000,
	border = false,
	borderColor = null,
	backgroundColor = null,
	distance = null,
	name = null,
	isHost = false,
}) {
	let imageWidth = width;
	let imageHeight = height;
	if (name) {
		imageWidth = Math.round(width / 1.225);
		imageHeight = Math.round(height / 1.225);
	}

	const svg = document.createElementNS(SVG_NAMESPACE, "svg");
	svg.setAttributeNS(null, "width", imageWidth);
	svg.setAttributeNS(null, "height", imageHeight);
	svg.setAttributeNS(null, "viewBox", `0 0 ${PLAYER_AVATAR_CANVAS_SIZE} ${PLAYER_AVATAR_CANVAS_SIZE}`);
	svg.setAttributeNS(null, "fill", "none");
	svg.setAttributeNS(null, "stroke-linecap", "round");
	svg.setAttributeNS(null, "stroke-linejoin", "round");

	let src = null;
	if (isArray(avatar)) {
		for (let i = 0; i < avatar.length; i++) {
			const layer = avatar[i];
			if (layer.url) {
				const text = await fetchTextAsync(PLAYER_AVATAR_BASE_PATH + layer.url);
				const path = `<g>${text}</g>`;

				if (path) {
					const x = layer.x || 0;
					const y = layer.y || 0;
					const z = layer.z || 0;

					const layerGroup = document.createElementNS(SVG_NAMESPACE, "g");
					layerGroup.setAttributeNS(null, "transform", `translate(${x} ${y}) scale(${z} ${z})`);
					layerGroup.innerHTML = path || "";
					svg.appendChild(layerGroup);
				}
			}
		}
		const outerHTML = new XMLSerializer().serializeToString(svg);
		src = "data:image/svg+xml;charset-utf-8," + encodeURIComponent(outerHTML);
	} else if (isString(avatar)) {
		let image = null;
		try {
			image = await new Promise((resolve, reject) => {
				const url = `${CDN_BASE_URL}/${avatar}`;
				const image = new Image();
				image.crossOrigin = "anonymous";
				image.onload = () => {
					image.onload = image.onerror = null;
					resolve(image);
				};
				image.onerror = (error) => {
					image.onload = image.onerror = null;
					reject(error);
				};
				image.src = url;
			});
		} catch (error) {
			console.error(error);
		}

		src = await new Promise((resolve, reject) => {
			const onLoad = () => {
				const canvas = document.createElementNS(XHTML_NAMESPACE, "canvas");
				canvas.width = width;
				canvas.height = height;
				const context = canvas.getContext("2d");

				if (border) {
					const lineWidth = isFinite(border) ? border : Math.min(imageWidth, imageHeight) * (1 / 32);

					context.save();

					context.beginPath();
					context.arc(
						width / 2,
						height / 2,
						Math.min(imageWidth, imageHeight) / 2 - lineWidth / 2,
						0,
						2 * Math.PI
					);
					context.clip();

					context.beginPath();
					context.rect(0, 0, width, height);
					context.fillStyle = backgroundColor || PLAYER_AVATAR_BACKGROUND_COLOR;
					context.fill();

					context.drawImage(
						image,
						(width - imageWidth) / 2,
						(height - imageHeight) / 2,
						imageWidth,
						imageHeight
					);

					context.restore();

					context.save();
					context.beginPath();
					context.arc(
						width / 2,
						height / 2,
						Math.min(imageWidth, imageHeight) / 2 - lineWidth / 2,
						0,
						2 * Math.PI
					);

					if (isHost) {
						context.lineWidth = lineWidth * 4;
						context.strokeStyle = BLACK;
						context.stroke();
						context.lineWidth = lineWidth * 2;
						context.strokeStyle = YELLOW_BROWN;
						context.stroke();
					} else {
						context.lineWidth = lineWidth;
						context.strokeStyle = borderColor || BLACK;
						context.stroke();
					}

					context.restore();
				} else {
					context.drawImage(image, (width - imageWidth) / 2, (height - imageHeight) / 2);
				}

				if (name) {
					createAvatarName(name, context, width, height);
				}

				if (isFinite(distance)) {
					drawDistance(distance, context, width, height);
				} else if (isHost) {
					drawHostBadge(context, width, height);
				}

				const dataURL = canvas.toDataURL(AVATAR_MIME_TYPE);

				resolve(dataURL);
			};

			if (!image) {
				if (src) {
					image = document.createElementNS(XHTML_NAMESPACE, "img");
					image.width = width;
					image.height = height;

					image.onload = () => {
						image.onerror = image.onload = null;
						onLoad();
					};
					image.onerror = (error) => {
						image.onerror = image.onload = null;
						reject(error);
					};
					image.src = src;
				} else {
					reject();
				}
			} else {
				onLoad();
			}
		});
	}

	if (!src) {
		throw new Error("Invalid src");
	}

	return src;
}
