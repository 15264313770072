import { PLACE_CODE_SEPARATOR } from "@/app-constants.mjs";
import { MAP_TYPE_REGULAR, PLACE_TYPE_COUNTRY, PLACE_TYPE_STATE } from "@/constants";
import isFinite from "lodash/isFinite";

export const RADIUS_MAX = 5e6;
export const RADIUS_MIN = 10;

export function haversineDistance(p1, p2) {
	var R = 6371e3; // Radius of the Earth in m

	if (![p1, p2].every((p) => p && ["lat", "lng"].every((prop) => Object.prototype.hasOwnProperty.call(p, prop)))) {
		console.error({ p1, p2 });
		return undefined;
	}

	var rlat1 = p1.lat * (Math.PI / 180); // Convert degrees to radians
	var rlat2 = p2.lat * (Math.PI / 180); // Convert degrees to radians
	var difflat = rlat2 - rlat1; // Radian difference (latitudes)
	var difflon = (p2.lng - p1.lng) * (Math.PI / 180); // Radian difference (longitudes)

	var d =
		2 *
		R *
		Math.asin(
			Math.sqrt(
				Math.sin(difflat / 2) * Math.sin(difflat / 2) +
					Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
			)
		);
	return d;
}

export function distanceString(meters) {
	const km = 0.001 * (isFinite(meters) ? meters : 0);

	return km < 1
		? `${Math.round(km * 1000)}m`
		: km < 100
		? `${km.toFixed(1)}km`
		: km < 1000
		? `${Math.round(km)}km`
		: `${Math.floor(Math.round(km) / 1000)} ${(Math.round(km) % 1000).toString().padStart(3, "0")}km`;
}

export function calculateGoogleMapZoom(element, latitude, distance, minZoomLevel = 1, maxZoomLevel = 18) {
	if (element) {
		// https://stackoverflow.com/questions/9356724/google-map-api-zoom-range
		const mapSize = { width: element.offsetWidth, height: element.offsetHeight };
		const pixels = Math.min(mapSize.width, mapSize.height); //get the shortest dimmension of the map
		const coverage = 0.7;
		const k = coverage * pixels * 156543.03392 * Math.cos((latitude * Math.PI) / 180);
		const zoom = Math.log(k / distance) / 0.6931471805599453 - 1;
		return zoom > maxZoomLevel ? maxZoomLevel : zoom < minZoomLevel ? minZoomLevel : zoom;
	}
	return 5;
}

export function deserialize(options) {
	let target = null;
	let view = null;

	try {
		const obj = JSON.parse(options[0].text);

		if (["lat", "lng", "r"].every((prop) => Object.prototype.hasOwnProperty.call(obj, prop))) {
			const { lat: targetLat, lng: targetLng, r: targetRadius } = obj;
			target = { lat: parseFloat(targetLat), lng: parseFloat(targetLng), radius: parseFloat(targetRadius) };
		} else if (["id"].every((prop) => Object.prototype.hasOwnProperty.call(obj, prop))) {
			const { id: placeCode } = JSON.parse(options[0].text);
			const placeType = placeCode.includes(PLACE_CODE_SEPARATOR) ? PLACE_TYPE_STATE : PLACE_TYPE_COUNTRY;
			target = { placeType, placeCode };
		}
	} catch (err) {
		//
	}

	try {
		const { lat: mapCenterLat, lng: mapCenterLng, m: mapType, z: mapZoom } = JSON.parse(options[1].text);
		view = {
			center: { lat: parseFloat(mapCenterLat), lng: parseFloat(mapCenterLng) },
			type: mapType || MAP_TYPE_REGULAR,
			zoom: parseFloat(mapZoom),
		};
	} catch (err) {
		view = { center: { lat: 0, lng: 0 }, zoom: 1, type: MAP_TYPE_REGULAR };
	}

	if (options.length > 2 && options[2].text) {
		const [west, south, east, north] = options[2].text.split("/").map(parseFloat);
		view.bounds = { west, south, east, north };
	}

	return { target, view };
}

export function serialize({ target, view }) {
	try {
		let opt0 = "";

		if (target?.radius) {
			opt0 = JSON.stringify({
				lat: parseFloat(target.lat),
				lng: parseFloat(target.lng),
				r: parseFloat(target.radius),
			});
		} else if (target?.placeCode) {
			opt0 = JSON.stringify({
				id: target.placeCode,
			});
		}

		const options = [
			{
				text: opt0,
				isCorrect: true,
			},
			{
				text: view
					? JSON.stringify({
							lat: parseFloat(view.center.lat),
							lng: parseFloat(view.center.lng),
							m: view.type,
							z: parseFloat(view.zoom),
					  })
					: "",
				isCorrect: true,
			},
		];

		return options;
	} catch (err) {
		return null;
	}
}
